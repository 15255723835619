@use './styling/custom-shape-divider';
@use './styling/back-to-top-button';
@use './styling/home-page-typing-animation';
@use './styling/center-line-circle-seperator';

// color variables
$dark-grey: #2e3440;
$light-grey-main: #2e3440;
$light-grey: #9e9e9e;
$white-font: #eceff4;
$blue-main: #88c0d0;

* {
  margin: 0;
  padding: 0;
}

/* Creates smooth scrolling animation.
Leaves padding on top so the navigation does not cover the content  */

html {
  scroll-behavior: smooth;
  scroll-padding-top: 5vh;
  // border: solid red;
  height: 100%;
  width: 100%;
  // overflow-x: hidden;
}

body {
  background-color: $dark-grey;
  color: $white-font;
  font-family: Montserrat;
}

// navigation bar
.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar {
  height: 7vh;
  background-color: $dark-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: solid;
  width: 100%;
  opacity: 0.99;
}

// styling navigation links on larger screen
.navlink-container {
  .navlink-links {
    color: #eceff4;
    text-decoration: none;
    padding: 10px;

    &:hover {
      background-color: #444444;
      border-radius: 10px;
    }
  }

  .navlink-links-active {
    color: #eceff4;
    text-decoration: none;
    padding: 10px;
    background-color: #444444;
    border-radius: 10px;
  }
}

// styling navigation links on mobile screen

.navlink-mobile-list {
  .navlink-mobile-link {
    color: #eceff4;
    text-decoration: none;
  }
}

.navlink-mobile-link {
  .navlink-mobile-listitem {
    padding: 25px;

    &:hover {
      background-color: #444444;
    }
  }
  .navlink-mobile-listitem-active {
    padding: 25px;
    background-color: #444444;
  }
}

// display profile picture on About page
.display-photo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-image: url('./img/profile.jpg');
  background-position: top;
  margin: 50px 0 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

// Projects container with images and heading
.project-container {
  // border: solid;
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-img-container {
  height: auto;
  // border: solid red;
  width: 350px;
  height: 200px;
  margin: 40px;
}

.project-img-container a {
  text-decoration: none;
  color: black;
}

.project-img {
  display: block;
  width: 100%;
}

// overlay hover text in the image - project page

.container {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: $blue-main;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.container:hover .overlay {
  bottom: 0;
  height: 100%;
}

.text {
  white-space: nowrap;
  color: $white-font;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

// underline design for the page titles
.text-bar {
  height: 20%;
  width: 100%;
  background-color: $white-font;
  color: $dark-grey;
  // border: solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-line {
  background-color: #9e9e9e;
  width: 135px;
  height: 0.5px;
  margin: 10px 0 10px 0;
}

.work-line-opposite {
  background-color: #9e9e9e;
  width: 135px;
  height: 0.5px;
  margin: 10px 0 10px 338px;
}

// text align left or right for the heading in "work" and "education" page depending on the screen size

@media screen and (max-width: 900px) {
  .work-text-align {
    text-align: left;
    margin-left: 17vw;
  }
}

@media screen and (min-width: 901px) {
  .work-text-align {
    text-align: right;
  }
}

.card-container {
  height: 230px;
  width: 300px;
  background-color: antiquewhite;
}

// footer styles
.card-footer {
  height: 30px;
  width: 30px;
  background-color: $light-grey-main;
}

.card-outer {
  transition: 0.3s;
  width: 300px;
  background-color: $blue-main;
  border-radius: 5px;
}

.card-outer:hover {
  transform: scale(1.1);
}

.card-outer a {
  text-decoration: none;
}

.container-inner {
  padding: 10px 10px;
  color: $dark-grey;
}

.container-inner p {
  text-transform: uppercase;
  text-align: center;
}

.home-text-blue {
  color: $blue-main;
}

.error-text {
  color: #dc143c;
}

section {
  width: 100vw;
  height: auto;
  width: 100%;
  position: relative;
}

// contact page

.contact-container {
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0;
  // border: solid black;
  height: auto;
  width: 90%;
  max-width: 1200px;
  padding: 0 0 20px 0;
}

.contact-container-info {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // border: solid $white-font;
  padding: 0 0 50px 0;
}

.contact-container-post {
  // border: solid red;
  display: flex;
  align-items: center;
  width: 100%;
}

// contact page desktop view

@media screen and (min-width: 600px) {
  .contact-container {
    display: flex;
    flex-direction: row;
    // border: solid black;
    height: 600px;
    width: 90%;
    max-width: 1200px;
  }

  .contact-container-info {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: solid $white-font;
    padding: 0 10px 50px 10px;
  }

  .contact-container-post {
    // border: solid red;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
