// custom shape divider
// home
.custom-shape-divider-bottom-1648784775 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1648784775 svg {
  position: relative;
  display: block;
  width: calc(173% + 1.3px);
  height: 92px;
}

.custom-shape-divider-bottom-1648784775 .shape-fill {
  fill: #292e39;
}

// about
.custom-shape-divider-bottom-1648782302 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1648782302 svg {
  position: relative;
  display: block;
  width: calc(140% + 1.3px);
  height: 73px;
}

.custom-shape-divider-bottom-1648782302 .shape-fill {
  fill: #2e3440;
}

// work
.custom-shape-divider-bottom-1649639648 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1649639648 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 33px;
}

.custom-shape-divider-bottom-1649639648 .shape-fill {
  fill: #292e39;
}
// projects
.custom-shape-divider-bottom-1649646734 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1649646734 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 33px;
}

.custom-shape-divider-bottom-1649646734 .shape-fill {
  fill: #2e3440;
}

// education
.custom-shape-divider-bottom-1648873246 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1648873246 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 112px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1648873246 .shape-fill {
  fill: #292e39;
}
// contact
.custom-shape-divider-bottom-1648872746 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1648872746 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 72px;
}

.custom-shape-divider-bottom-1648872746 .shape-fill {
  fill: #2e3440;
}

// footer
.custom-shape-divider-bottom-1649041410 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1649041410 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 49px;
}

.custom-shape-divider-bottom-1649041410 .shape-fill {
  fill: #2e3440;
}
