/* home page animation  */

// mobile view

.typing-demo {
  width: 25ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: 5vw;
  // font-size: 27px;
  font-family: Rubik;
  text-align: center;
  // font-weight: bold;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

// tablet view

@media screen and (min-width: 600px) {
  .typing-demo {
    // font-size: 5vw;
  }
}

// PC view

@media screen and (min-width: 900px) {
  .typing-demo {
    font-size: 45px;
  }
}
