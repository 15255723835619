// back to top button
.back-to-top-button {
  position: fixed;
  bottom: 70px;
  right: 25px;
  height: 50px;
  width: 50px;
  z-index: 2000;
  cursor: pointer;
  border-radius: 15px;
  border: none;
  color: #2e3440;
  background-color: #88c0d0;
}

.back-to-top-button:hover {
  filter: brightness(65%);
}

@media screen and (min-width: 600px) {
  .back-to-top-button {
    bottom: 25px;
  }
}
